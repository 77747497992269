<template>
  <v-dialog
    v-model="selecionar"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-container class="fill-height" fluid style="background-color: #fff">
      <v-row justify="center">
        <v-col align="center" cols="12">
          <img
            :src="require('@/assets/logo.png')"
            height="30"
            style="vertical-align: middle; padding-right: 15px"
          />
        </v-col>
        <v-col align="center" cols="12" sm="6" md="6" lg="6" xl="6">
          <v-card color="#2bbdeb" rounded="lg">
            <v-row>
              <v-col align="center" cols="12" md="6" lg="6" xl="6">
                <div class="text-h6 color-white">
                  Bem-vindo ao <br />Painel do Comitente
                </div>
                <slot></slot>
              </v-col>
              <v-col cols="6" align-self="center" class="pa-5 d-none d-md-flex">
                <v-img
                  :src="require('@/assets/painel_img1.png')"
                  max-width="220"
                ></v-img>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" align="center"> </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  props: ['selecionar'],
};
</script>

<style>
.color-white {
  color: #fff;
}
</style>
